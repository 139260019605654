import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
    picture: {
        display: "flex"
    }
}));

const NeuralXImage = ({
    sources,
    width,
    height,
    className,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <picture
            {...rest}
            className={clsx(classes.picture, className)}>
            {sources.map(source => (
                <source
                    key={source.srcSet}
                    srcSet={source.srcSet}
                    type={source.type} />
            ))}
            <img
                width={width}
                height={height}
                className={className}
                src="/imgs/placeholder.gif"
                alt="NeuralX" />
        </picture>
    );
};

export default NeuralXImage;
