const TRAINER_ATTRIBUTES = {
    TRAINER_ID: "trainer_id",
    EMAIL: "email",
    FAMILY_NAME: "family_name",
    IMAGE: "image",
    IMAGE_UPLOAD: "image_upload",
    INSTRUCTOR_SIGNUPS: "instructor_signups",
    PUBLISHED: "published",
    NAME: "name",
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name"
};

export default TRAINER_ATTRIBUTES;
