import { makeVar } from "@apollo/client";
import PAGE_NAMES from "utils/constants/page-names";

const notification = makeVar({});
const currentPageCache = makeVar(PAGE_NAMES.MAIN.name);
const profileChangedCache = makeVar(false);

export {
    notification,
    currentPageCache,
    profileChangedCache
};
