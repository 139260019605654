const dogPoundUrls = [
    "dogpound.local.com",
    "dogpound.trainer.stage.presence.fit",
    "trainer.thedogpound.com"
];

const pf3Urls = [
    "pf3.local.com",
    "pf3.trainer.stage.presence.fit",
    "trainer.pf3.com"
];

export function getClientConfig(config) {
    if (isDodPoundUrl()) {
        if (config === "secret") {
            return DOG_POUND_SECRET_KEY;
        } else if (config === "platform_name") {
            return DOG_POUND_PLATFORM_NAME;
        } else if (config === "favicon") {
            return DOG_POUND_FAVICON;
        } else if (config === "logo_path") {
            return DOG_POUND_LOGO_PATH;
        }
    } else if (isPF3Url()) {
        if (config === "secret") {
            return PF3_SECRET_KEY;
        } else if (config === "platform_name") {
            return PF3_PLATFORM_NAME;
        } else if (config === "favicon") {
            return PF3_FAVICON;
        } else if (config === "logo_path") {
            return PF3_LOGO_PATH;
        }
    } else {
        if (config === "secret") {
            return FITNESS_SECRET_KEY;
        } else if (config === "platform_name") {
            return PRESENCE_PLATFORM_NAME;
        } else if (config === "favicon") {
            return PRESENCE_FAVICON;
        } else if (config === "logo_path") {
            return PRESENCE_LOGO_PATH;
        }
    }
}

export function isDodPoundUrl() {
    const hostName = window.location.hostname;
    return dogPoundUrls.includes(hostName);
}

export function isPF3Url() {
    const hostName = window.location.hostname;
    return pf3Urls.includes(hostName);
}

const FITNESS_SECRET_KEY = "sk_ft_PoW3r37g9OUWA7S39KKE";
const DOG_POUND_SECRET_KEY = "sk_dp_PoW3r37g9OUWA7S39MMJ";
const PF3_SECRET_KEY = "sk_pf3_PoW3r37g9OUWA7S39OOZ";

const DOG_POUND_PLATFORM_NAME = "DOGPOUND";
const PRESENCE_PLATFORM_NAME = "Presence";
const PF3_PLATFORM_NAME = "PF3";

const DOG_POUND_LOGO_PATH = "dp_logo";
const PRESENCE_LOGO_PATH = "logo";
const PF3_LOGO_PATH = "pf3_logo";

const DOG_POUND_FAVICON = "/favicon-dp.ico";
const PRESENCE_FAVICON = "/favicon.ico";
const PF3_FAVICON = "/favicon.ico";

export const getLogoImageImage = () => {
    const logoPath = getClientConfig("logo_path");
    const SOURCES_DP = [
        {
            srcSet: "/imgs/"+ logoPath +"/webp/logo.webp",
            type: "image/webp"
        },
        {
            srcSet: "/imgs/"+ logoPath +"/png/logo.png",
            type: "image/png"
        }
    ];
    return SOURCES_DP;
};

export function getSecretKey() {
    return getClientConfig("secret");
}

export function getPlatformName() {
    return getClientConfig("platform_name");
}

export function getPlatformFavIcon() {
    return getClientConfig("favicon");
}
