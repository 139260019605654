import { gql } from "@apollo/client";

import { LOGIN_TOKENS } from "utils/constants/authentications";
import TRAINER_ATTRIBUTES from "utils/constants/trainer-attributes";
import CONFIRMATION_RESPONSES from "utils/constants/confirmation-responses";
import { SESSION_ATTRIBUTES } from "utils/constants/authentications";

const TRAINER_LOGIN = gql`
    query trainerLogin($email: String!, $password: String!) {
        trainerLogin(email: $email, password: $password) {
            success
            ${LOGIN_TOKENS.ACCESS_TOKEN}
            ${LOGIN_TOKENS.ID_TOKEN}
            ${LOGIN_TOKENS.REFRESH_TOKEN}
        }
    }
`;

const TRAINER_GET = gql`
    query trainerGet($trainer_id: ID!) {
        trainerGet(trainer_id: $trainer_id) {
            ${TRAINER_ATTRIBUTES.IMAGE}
        }
    }
`;

const TRAINER_LOGIN_REFRESH_TOKEN = gql`
    query trainerLoginRefreshToken($refresh_token: String!) {
        trainerLoginRefreshToken(refresh_token: $refresh_token) {
            success
            ${LOGIN_TOKENS.ID_TOKEN}
        }
    }
`;

const TRAINER_FIELD_CHECK = gql`
    query trainerFieldPrecheck($field: String!, $context: String!, $value: String!) {
        trainerFieldPrecheck(field: $field, context: $context, value: $value) {
            ${CONFIRMATION_RESPONSES}
        }
    }
`;

const TRAINER_RESEND_VERIFICATION_LINK = gql`
    query trainerResendVerificationLink($email: String!) {
        trainerResendVerificationLink(email: $email) {
            ${CONFIRMATION_RESPONSES}
        }
    }
`;

const TRAINER_GET_ALL_UNSECURE = gql`
    query trainerGetAllUnsecure {
        trainerGetAllUnsecure {
            ${TRAINER_ATTRIBUTES.TRAINER_ID}
            ${TRAINER_ATTRIBUTES.NAME}
            ${TRAINER_ATTRIBUTES.IMAGE}
            ${TRAINER_ATTRIBUTES.INSTRUCTOR_SIGNUPS} {
                ${SESSION_ATTRIBUTES.SESSION_ID}
            }
        }
    }
`;

export {
    TRAINER_GET_ALL_UNSECURE,
    TRAINER_LOGIN,
    TRAINER_GET,
    TRAINER_LOGIN_REFRESH_TOKEN,
    TRAINER_FIELD_CHECK,
    TRAINER_RESEND_VERIFICATION_LINK
};
