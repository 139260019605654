import STORAGE_NAMES from "utils/constants/storage-names";

const setStorage = ({ storageName, data }) => {
    Object.entries(data).forEach(([key, value]) => {
        storageName === STORAGE_NAMES.LOCAL ? localStorage.setItem(key, value) : sessionStorage.setItem(key, value);
    });
};

const removeStorage = ({ storageName, key }) => {
    storageName === STORAGE_NAMES.LOCAL ? localStorage.removeItem(key) : sessionStorage.removeItem(key);
};

const clearStorage = storageName => {
    if (!storageName) {
        localStorage.clear();
        sessionStorage.clear();
    } else {
        storageName === STORAGE_NAMES.LOCAL ? localStorage.clear() : sessionStorage.clear();
    }
};

const getItemFromStorage = ({ storageName, name }) => {
    return storageName === STORAGE_NAMES.LOCAL ? localStorage.getItem(name) : sessionStorage.getItem(name);
};

export {
    setStorage,
    removeStorage,
    clearStorage,
    getItemFromStorage
};
