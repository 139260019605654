
export default {
    HOME: "/",
    CLASS_DETIAL: "/class-detail",
    NEW_CLASS: "/new-class",
    MY_CLASSES: "/my-classes",
    CLIPS: "/clips",
    LIVE: "/live",
    LOGIN: "/login",
    PROFILE: "/profile",
    RESEND_VERIFICATION: "/resend-verification",
    RESET_PASSWORD: "/reset-password",
    MUSIC_STATIONS: "/launch-music-stations/:session_id",
    CALENDAR: "/calendar",
    TRAINING: "/training",
    TRAINING_CALENDAR: "/training-calendar"
};
