
const convertDataUrlToBlob = dataUrl => {
    // Source: https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript

    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataUrl.split(",")[1]);

    // separate out the mime component
    const mimeString = dataUrl.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
};

const getProfileImage = () => {
    const profileImageUrl = sessionStorage.getItem("profileImage");
    return profileImageUrl || window.defaultImage;
};

const sortItemsByField = (items, field) => {
    return items.slice().sort((i1, i2) => {
        if (i1[field] < i2[field]) {
            return -1;
        } else if (i1[field] > i2[field]) {
            return 1;
        }
        return 0;
    });
};

const sortItemsByLookupTable = (items, field, lookupTable, lookupTableField) => {
    return items.slice().sort((i1, i2) => {
        const i1TableEntry = lookupTable[i1[field]];
        const i2TableEntry = lookupTable[i2[field]];

        const i1Val = i1TableEntry[lookupTableField];
        const i2Val = i2TableEntry[lookupTableField];

        if (i1Val < i2Val) {
            return -1;
        } else if (i1Val > i2Val) {
            return 1;
        }
        return 0;
    });
};

const stringifyParsedObject = parsedObject => {
    if (!(parsedObject instanceof Array || parsedObject instanceof Object)) { // parse recursion terminated in a literal
        return `"${parsedObject}"`;
    } else if (parsedObject instanceof Array) {
        const nestedEntries = parsedObject.map(entry => stringifyParsedObject(entry)).join();
        return `[${nestedEntries}]`;
    }

    // Guaranteed to be an object after this point

    const entries = Object.keys(parsedObject).map(key => {
        const value = parsedObject[key];
        const evaluated = stringifyParsedObject(value);
        return `"${key}": ${evaluated}`;
    });

    return `{${entries.join()}}`;
};

const validateMinimumTier = minimumTier => {
    const tier = sessionStorage.getItem("tier");

    const parsedTrainerTier = parseInt(tier);
    const parsedMinimumTier = parseInt(minimumTier);

    return parsedTrainerTier >= parsedMinimumTier;
};

const upperCase = str => {
    if (str) {
        const convertedStr = str.split("_").join(" ").split("-").join(" ");
        return convertedStr.toUpperCase();
    }
    return "";
};

const getFirstCharacter = str => {
    if (str) {
        return str.charAt(0);
    } else {
        return "";
    }
};

export {
    convertDataUrlToBlob,
    getFirstCharacter,
    getProfileImage,
    sortItemsByField,
    sortItemsByLookupTable,
    stringifyParsedObject,
    validateMinimumTier,
    upperCase
};
