import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Offline as OfflineIntegration } from "@sentry/integrations";
import ReactGA from "react-ga4";
import client from "apollo/lib";
import { APP_BUIDS } from "utils/constants/commons";
import App from "./App";
import "./index.css";

import ErrorBoundary from "./pages/ErrorPage";

window.build = process.env.REACT_APP_BUILD || APP_BUIDS.LOCAL;
window.defaultImage = "imgs/placeholder.gif";

Sentry.init({
    environment: process.env.REACT_APP_BUILD || APP_BUIDS.LOCAL,
    dsn: "https://11f9b96bf06b41c5bb2b42dd39171b04@o509016.ingest.sentry.io/5602594",
    integrations: [new OfflineIntegration(
        {
            // limit how many events will be localled saved. Defaults to 30.
            maxStoredEvents: 50
        }
    )]
});

const MEASUREMENT_ID = process.env.NODE_ENV === "production" ? "G-XZLYM06VG6" : "G-ZV1CRVMEE3";
ReactGA.initialize(MEASUREMENT_ID);
ReactGA.send("pageview");

ReactDOM.render(
    <ErrorBoundary>
        <ApolloProvider client={client}>
            <Router>
                <App />
            </Router>
        </ApolloProvider>
    </ErrorBoundary>,
    document.getElementById("root")
);
