const APP_BUIDS = {
    LOCAL: "local",
    PRODUCTION: "prod",
    STAGE: "stage"
};

const EQUIPMENT_ATTRIBUTES = {
    EQUIPMENT_ID: "equipment_id",
    EQUIPMENT_COUNT: "equipment_count"
};


const ORGANIZATION_ATTRIBUTES = {
    ORGANIZATION_ID: "organization_id",
    ORGANIZATION_COUNT: "organization_count"
};

const INPUT_TEXT_RESTRICITON_SETS = {
    SPECIAL_CHARACTER_FREE: "[^a-zA-Z0-9 ]"
};

const PUBLICATION_STATUS = {
    ERROR: "E",
    PUBLISHED: "P",
    UNPUBLISHED: "U"
};

const TAG_ATTRIBUTES = {
    TAG_ID: "tag_id",
    TAG_COUNT: "tag_count"
};

const TIERS = {
    ADMIN: "3",
    GROUP: "1",
    PERSONAL: "2"
};

const SPECIAL_TAGS = {
    LIVE: {
        id: "LIVE-CLASSES",
        name: "LIVE CLASSES ONLY",
        q: { live_only: "true" }
    },
    AI_TRAINING: {
        id: "AI TRAINING",
        name: "AI TRAINING ONLY",
        q: { ai_training_only: "true" }
    },
    ON_DEMAND: {
        id: "PRE-RECORDED",
        name: "ON DEMAND ONLY",
        q: { on_demand_only: "true" }
    },
    ALL_CLASSES: {
        id: "ALL-CLASSES",
        name: "ALL CLASSES",
        q: { all_classes: "true" }
    }
};

export {
    APP_BUIDS,
    EQUIPMENT_ATTRIBUTES,
    INPUT_TEXT_RESTRICITON_SETS,
    PUBLICATION_STATUS,
    SPECIAL_TAGS,
    TAG_ATTRIBUTES,
    TIERS,
    ORGANIZATION_ATTRIBUTES
};
