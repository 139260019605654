const AUTHENTICATION_CONTEXTS = {
    PASSWORD_RESET: "PASSWORD_RESET",
    SIGNUP: "SIGNUP",
    VERIFICATION_LINK_RESEND: "VERIFICATION_LINK_RESEND"
};

const ID_TOKEN_ATTRIBUTES = {
    TRAINER_ID: "sub",
    EMAIL: "email",
    TIER: "custom:tier"
};

const LOGIN_TOKENS = {
    ACCESS_TOKEN: "access_token",
    ID_TOKEN: "id_token",
    REFRESH_TOKEN: "refresh_token"
};

const SESSION_ATTRIBUTES = {
    SESSION_ID: "session_id",
    ACTION: "action",
    CHANNELS: "channels",
    CLIENT_TIME_ZONE: "client_time_zone",
    START_DATE_TIME: "start_date_time",
    SESSION_DATE_TIMES: "session_date_times",
    STATUS: "status",
    TRAINER: "trainer",
    CLASS: "class",
    IS_FULL: "is_full",
    PAUSED: "paused",
    RUN_TIME: "run_time",
    VIDEO_STATE: "video_state",
    SIGNUPS: "signups",
    ROOM_STATES: "room_states",
    SPEAKING_STATES: "speaking_states",
    TYPE: "type",
    MUSIC_MESSAGE_FOR_LIVE_SESSION_PAGE: "music_message_for_live_session_page",
    MUSIC_MESSAGE_FOR_MUSIC_STATION_PAGE: "music_message_for_music_station_page"
};

const SESSION_TYPE = {
    LIVE: "live", // Live session created by trainer and attended by customers
    SOCIAL_LIVE: "sociallive", // Live session created by customer and attended by other customers
    ON_DEMAND: "ondemand" // Session attended by customer individual
};

const SESSION_STATES = {
    ATTENDANT_ID: "attendant_id",
    STATE: "state"
};

const USER_TYPES = {
    CUSTOMER: "customer",
    TRAINER: "trainer",
    PARTICIPANT: "participant" // LEGACY
};

export {
    AUTHENTICATION_CONTEXTS,
    ID_TOKEN_ATTRIBUTES,
    LOGIN_TOKENS,
    SESSION_ATTRIBUTES,
    USER_TYPES,
    SESSION_STATES,
    SESSION_TYPE
};
