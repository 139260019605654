import { Button } from '@material-ui/core';
import React, { Component } from 'react';
import NeuralXImage from "components/NeuralXImage";
import { getLogoImageImage } from "utils/helpers/client-config";
import "./error.css";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    handleClick = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
        window.location.reload()
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <NeuralXImage
                        sources={getLogoImageImage()}
                        className='logo'
                    />
                    <div className='center'>
                        <h2>Uh oh, something went wrong!</h2>
                        <p>Try pressing Cmd + Shift + R on your keyboard to refresh the page.</p>
                        <p>If that does not resolve your issue, please restart chrome, and re-open this page.</p>
                        <Button
                            onClick={this.handleClick}
                            style={{ 
                                backgroundColor: "white",
                                color: "black",
                                fontWeight: "bold",
                                borderRadius: 20,
                                padding: 8,
                                marginTop: 20
                            }}
                        >
                            RELOAD PAGE
                        </Button>
                    </div>
                </>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;