import { ApolloClient, InMemoryCache, split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "apollo-link-context";
import { getSecretKey } from "utils/helpers/client-config";

import { BACKEND_GRAPHQL_URL, BACKEND_SUBSCRIPTION_URL } from "configs";
import { notification } from "apollo/cache";

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                notifications: {
                    read() {
                        return notification();
                    }
                }
            }
        }
    }
});

const wsLink = new WebSocketLink({
    uri: BACKEND_SUBSCRIPTION_URL,
    options: {
        reconnect: true
    }
});

const httpLink = new HttpLink({
    uri: BACKEND_GRAPHQL_URL
});

let link = httpLink;

const middlewareLink = setContext(() => ({
    headers: {
        'H-Platform-Secret-Key': getSecretKey(),
    },
}));

link = middlewareLink.concat(httpLink);

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    wsLink,
    link
);

const client = new ApolloClient({
    link: splitLink,
    cache
});

export default client;
